import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Meditation App Template | Meditation App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/meditation-mobile-app/"
    metaDescription="Introducing Zenify, our meditation app design template for mobile. Relax and reach design zen with our pre-made template now."
    description="
    h2:Introducing Zenify, our mobile meditation app design template
    <br/>
    Kick back, relax, and design your own meditation app with Zenify, our mobile meditation app design template. Our templates come packed with everything you could possibly need to make your own app design a success. With endless optimization options, you can't go wrong with Zenify.
    <br/>
    h3:Relax, clear your mind, streamline your design
    <br/>
    Clear your mind, focus your energies… Zenify is the perfect solution to streamline your mobile meditation app design idea. As with all our templates, you can use Zenify straight out of the box as it comes with all the screens and features you could possibly need. And, with our easy customization options, you can have your own <a:https://uizard.io/mockups/>design mockup</a> ready in no time at all.
    <br/>
    h3:Teamwork makes the dream work
    <br/>
    Easily share your meditation app design with colleagues, friends, and team members using Uizard's smart sharing features. Team working during the design process has never been easier than it is with Uizard <a:https://uizard.io/templates/>UI templates</a>.
    "
    pages={[
      "A sleek, minimalist landing page screen",
      "A suave app interface design demonstrating how the user will interact with your app on a minute-to-minute basis",
      "Meditation sections broken down by mood, with search results pages to illustrate content feeds",
      "A start session page illustrating app usability",
    ]}
    projectCode="RKqK03A0qesVoKWz0mKw"
    img1={data.image1.childImageSharp}
    img1alt="meditation app design overview"
    img2={data.image2.childImageSharp}
    img2alt="meditation app design session search screen"
    img3={data.image3.childImageSharp}
    img3alt="meditation app design stats screen"
    img4={data.image4.childImageSharp}
    img4alt="meditation app design session finish screen"
    img5={data.image5.childImageSharp}
    img5alt="meditation app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/meditation-mobile-app/meditation-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/meditation-mobile-app/meditation-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/meditation-mobile-app/meditation-app-stats.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/meditation-mobile-app/meditation-app-finish.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/meditation-mobile-app/meditation-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
